define("accounting/format-money", ["exports", "accounting/unformat", "accounting/format-number", "accounting/settings", "accounting/utils", "accounting/to-fixed"], function (_exports, _unformat, _formatNumber, _settings, _utils, _toFixed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Format a number into currency
   *
   * Usage: accounting.formatMoney(number, symbol, precision, thousandsSep, decimalSep, format)
   * defaults: (0, "$", 2, ",", ".", "%s%v")
   *
   * Localise by overriding the symbol, precision, thousand / decimal separators and format
   * Second param can be an object matching `settings.currency` which is the easiest way.
   *
   * ```js
   * // Default usage:
   * accounting.formatMoney(12345678); // $12,345,678.00
   *
   * // European formatting (custom symbol and separators), can also use options object as second parameter:
   * accounting.formatMoney(4999.99, "€", 2, ".", ","); // €4.999,99
   *
   * // Negative values can be formatted nicely:
   * accounting.formatMoney(-500000, "£ ", 0); // £ -500,000
   *
   * // Simple `format` string allows control of symbol position (%v = value, %s = symbol):
   * accounting.formatMoney(5318008, { symbol: "GBP",  format: "%v %s" }); // 5,318,008.00 GBP
   * ```
   *
   * @method formatMoney
   * @for accounting
   * @param {Number}        number Number to be formatted.
   * @param {Object|String} [symbol="$"] String with the currency symbol. For conveniency if can be an object containing all the options of the method.
   * @param {Integer}       [precision=2] Number of decimal digits
   * @param {String}        [thousand=','] String with the thousands separator.
   * @param {String}        [decimal="."] String with the decimal separator.
   * @param {String}        [format="%s%v"] String with the format to apply, where %s is the currency symbol and %v is the value.
   * @return {String} The given number properly formatted as money.
   */
  function formatMoney(number, symbol, precision, thousand, decimal, format) {
    // Resursively format arrays:
    if (Array.isArray(number)) {
      return number.map(function (val) {
        return formatMoney(val, symbol, precision, thousand, decimal, format);
      });
    }

    // Clean up number:
    number = (0, _unformat.default)(number);

    // Build options object from second param (if object) or all params, extending defaults:
    const opts = (0, _utils.defaults)((0, _utils.isObject)(symbol) ? symbol : {
      symbol: symbol,
      precision: precision,
      thousand: thousand,
      decimal: decimal,
      format: format
    }, _settings.currency);

    // Check format (returns object with pos, neg and zero):
    const formats = (0, _utils.checkCurrencyFormat)(opts.format);

    // Clean up precision
    const usePrecision = (0, _utils.checkPrecision)(opts.precision);

    // fixedNumber's value is not really used, just used to determine negative or not
    const fixedNumber = (0, _toFixed.default)(number || 0, usePrecision);
    // Choose which format to use for this value:
    const useFormat = fixedNumber > 0 ? formats.pos : fixedNumber < 0 ? formats.neg : formats.zero;

    // Return with currency symbol added:
    return useFormat.replace('%s', opts.symbol).replace('%v', (0, _formatNumber.default)(Math.abs(number), (0, _utils.checkPrecision)(opts.precision), opts.thousand, opts.decimal));
  }
  var _default = formatMoney;
  _exports.default = _default;
});