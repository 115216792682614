define("ember-ace/components/ember-ace-completion-tooltip", ["exports", "@ember/component", "ember-ace/templates/components/ember-ace-completion-tooltip"], function (_exports, _component, _emberAceCompletionTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _emberAceCompletionTooltip.default,
    tagName: ''
  });
  _exports.default = _default;
});