define("ember-svg-jar/inlined/thumbsup-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.086.594A1 1 0 0110 0a4 4 0 014 4v3h4.655a3 3 0 012.994 3.45l-1.38 9A3 3 0 0117.275 22H3a3 3 0 01-3-3v-7a3 3 0 013-3h2.35L9.086.594zM5 11H3a1 1 0 00-1 1v7a1 1 0 001 1h2v-9zm2 9v-9.788l3.608-8.118A2 2 0 0112 4v4a1 1 0 001 1h5.671a1 1 0 011 1.15l-1.38 9a1 1 0 01-1 .85H7z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});