define("ember-svg-jar/inlined/profile-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.546 14.223c0-1.964-1.466-3.556-3.273-3.556H4.727c-1.807 0-3.272 1.592-3.272 3.556\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><ellipse cx=\"8\" cy=\"4.667\" rx=\"3.636\" ry=\"3.333\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});