define("ember-arg-types/-private/throw-console-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let throwConsoleError;
  {
    throwConsoleError = () => {
      throw new Error('throwConsoleError() is not available in production');
    };
  }
  var _default = throwConsoleError;
  _exports.default = _default;
});