define("ember-ace/templates/components/ember-ace-completion-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y38m3em5",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,\"data-rendered-suggestion\",\"\"],[12],[18,1,[[33,1]]],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"suggestion\",\"yield\"]]",
    "moduleName": "ember-ace/templates/components/ember-ace-completion-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});