define("ember-svg-jar/inlined/nav-profile-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 15.222a4.556 4.556 0 014.556-4.555h7.11a4.556 4.556 0 014.556 4.555V17a1 1 0 11-2 0v-1.778a2.555 2.555 0 00-2.555-2.555H4.556A2.556 2.556 0 002 15.222V17a1 1 0 11-2 0v-1.778zM3.556 4.556a4.556 4.556 0 119.11 0 4.556 4.556 0 01-9.11 0zM8.11 2a2.556 2.556 0 100 5.111 2.556 2.556 0 000-5.111zM16.92 11.532a1 1 0 011.219-.718 4.556 4.556 0 013.417 4.408V17a1 1 0 11-2 0v-1.777a2.555 2.555 0 00-1.917-2.473 1 1 0 01-.718-1.218zM13.365.867a1 1 0 011.216-.72 4.556 4.556 0 010 8.826 1 1 0 01-.496-1.937 2.556 2.556 0 000-4.952 1 1 0 01-.72-1.217z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "18",
      "viewBox": "0 0 22 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});