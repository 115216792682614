define("ember-svg-jar/inlined/recurring-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M41.294 6.267H6.706a4.941 4.941 0 00-4.94 4.94v34.588a4.941 4.941 0 004.94 4.942h34.588a4.941 4.941 0 004.941-4.942V11.208a4.941 4.941 0 00-4.941-4.941zM32.755 1.89v8.754M15.246 1.89v8.754M4.302 19.399h39.396\" stroke=\"#D9D9D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M25.17 35.41l4.086 4.086-4.086 4.087\" stroke=\"#D9D9D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.18 32.958v3.27a3.27 3.27 0 003.269 3.268h9.807M22.718 32.958l-4.087-4.086 4.087-4.087\" stroke=\"#D9D9D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M31.708 35.41v-3.27a3.27 3.27 0 00-3.27-3.268h-9.807\" stroke=\"#D9D9D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "48",
      "height": "52",
      "viewBox": "0 0 48 52",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});