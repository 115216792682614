define("ember-svg-jar/inlined/icon_recurring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.185.536A.66.66 0 019.118.53l2.088 2.063a.66.66 0 010 .939L9.117 5.594a.66.66 0 11-.928-.939l1.613-1.593L8.19 1.47a.66.66 0 01-.005-.933z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.165 3.722c-.938 0-1.505.624-1.505 1.174v.916a.66.66 0 01-1.32 0v-.916c0-1.476 1.372-2.494 2.825-2.494h7.577a.66.66 0 010 1.32H3.165zM3.557 7.411a.66.66 0 01-.006.933L1.94 9.937l1.612 1.594a.66.66 0 11-.927.938L.536 10.408a.66.66 0 010-.939l2.088-2.063a.66.66 0 01.933.006z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.742 6.527a.66.66 0 01.66.66v.917c0 1.476-1.372 2.494-2.825 2.494H1a.66.66 0 010-1.32h7.577c.938 0 1.505-.624 1.505-1.174v-.917a.66.66 0 01.66-.66z\" fill=\"#000\"/>",
    "attrs": {
      "width": "12",
      "height": "13",
      "viewBox": "0 0 12 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});