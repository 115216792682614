define("ember-svg-jar/inlined/star-inactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 0a1 1 0 01.897.557l2.857 5.79 6.39.934a1 1 0 01.554 1.705l-4.623 4.503 1.09 6.362a1 1 0 01-1.45 1.054L11 17.9l-5.715 3.005a1 1 0 01-1.45-1.054l1.09-6.362L.302 8.986a1 1 0 01.553-1.705l6.39-.935L10.104.557A1 1 0 0111 0zm0 3.26L8.807 7.702a1 1 0 01-.752.546l-4.907.718 3.55 3.457a1 1 0 01.288.885l-.838 4.883 4.386-2.307a1 1 0 01.931 0l4.387 2.307-.838-4.883a1 1 0 01.288-.885l3.55-3.457-4.907-.718a1 1 0 01-.752-.546L11 3.259z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});