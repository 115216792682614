define("ember-promise-utils/utils/is-fulfilled", ["exports", "@ember/object/promise-proxy-mixin", "rsvp"], function (_exports, _promiseProxyMixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isFulfilled;
  /**
   * It's assumed if you call this method, it was previously checked that it is a promise
   * @param promise
   * @returns {boolean} whether promise has been fullfilled or not
   */
  function isFulfilled(promise) {
    if (_promiseProxyMixin.default.detect(promise)) {
      return !!promise.get('isFulfilled');
    }
    if (promise instanceof _rsvp.Promise) {
      return promise._state === 1;
    }

    // Can't detect it if its not one of the two kinds above
    return false;
  }
});