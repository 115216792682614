define("ember-cli-jsoneditor/components/json-editor", ["exports", "@ember/component", "@ember/runloop", "@ember/polyfills", "@ember/utils", "@ember/object"], function (_exports, _component, _runloop, _polyfills, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global JSONEditor */

  const possibleOptions = ['ace', 'ajv', 'escapeUnicode', 'history', 'modes', 'search', 'indentation', 'theme', 'disabled'];
  var _default = _component.default.extend({
    classNames: ['ember-cli-jsoneditor'],
    name: 'JSONEditor',
    mode: 'tree',
    history: true,
    search: true,
    indentation: 2,
    escapeUnicode: false,
    theme: 'ace/theme/jsoneditor',
    modes: null,
    disabled: false,
    onChange() {},
    onError() {},
    onModeChange() {},
    onEditable(e) {
      return e;
    },
    init() {
      this._super(...arguments);
      this.set('modes', ['tree', 'view', 'form', 'text', 'code']);
      (0, _object.defineProperty)(this, 'options', (0, _object.computed)(...possibleOptions, this.getOptions));
    },
    didInsertElement() {
      this._super(...arguments);
      this._createEditorPid = _runloop.run.scheduleOnce('afterRender', this, this.createEditor);
    },
    destroy() {
      this._super(...arguments);
      const editor = this.get('editor');
      if (!(0, _utils.isNone)(editor)) {
        editor.destroy();
      }
      _runloop.run.cancel(this._createEditorPid);
    },
    createEditor: (0, _object.observer)('options', function () {
      if (!this.notDestroyed()) {
        return;
      }
      const element = this.get('element');
      const editor = this.get('editor');
      if ((0, _utils.isNone)(element)) {
        return;
      }
      if (!(0, _utils.isNone)(editor)) {
        editor.destroy();
      }
      this.set('editor', new JSONEditor(element, this.get('options'), this.getJSON()));
    }),
    modeChanged: (0, _object.observer)('mode', function () {
      if (this.notDestroyed()) {
        this.get('editor').setMode(this.get('mode'));
      }
    }),
    nameChanged: (0, _object.observer)('name', function () {
      if (this.notDestroyed()) {
        this.get('editor').setName(this.get('name'));
      }
    }),
    schemaChanged: (0, _object.observer)('schema', function () {
      if (this.notDestroyed()) {
        this.get('editor').setSchema(this.get('schema'));
      }
    }),
    jsonChanged: (0, _object.observer)('json', function () {
      // Only update json if it was change programatically
      if (!this._isTyping && this.notDestroyed() && JSON.stringify(this.getJSON()) !== JSON.stringify(this.get('editor').get())) {
        this.get('editor').set(this.getJSON());
      }
    }),
    getOptions() {
      const options = this.getProperties(possibleOptions);
      (0, _polyfills.merge)(options, this.getProperties(['name', 'mode', 'schema']));
      if (options.disabled) {
        options.mode = 'view';
        options.modes = ['view'];
      }
      options.onChange = () => {
        this._isTyping = true;
        const editor = this.get('editor');
        try {
          this.get('onChange')(editor.get());
        } catch (err) {
          if ((0, _utils.isEmpty)(editor.getText())) {
            this.get('onChange')({});
          }
        }
        this._isTyping = false;
      };
      options.onError = this.get('onError');
      options.onModeChange = this.get('onModeChange');
      options.onEditable = this.get('onEditable');
      delete options.disabled;
      return options;
    },
    getJSON() {
      let json = this.get('json');
      if (typeof json === "string") {
        return JSON.parse(json);
      }
      return json;
    },
    notDestroyed() {
      return !this.get('isDestroyed') && !this.get('isDestroyed');
    }
  });
  _exports.default = _default;
});