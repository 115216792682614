define("ember-svg-jar/inlined/thumbsup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.85 16.234c.096.535.567.925 1.115.916H4.4V9.5H2.965c-.548-.01-1.019.38-1.115.915v5.82zm4.25.916V8.83l3.067-6.9a1.7 1.7 0 011.183 1.62v3.4c0 .47.38.85.85.85H16.02a.85.85 0 01.85.977l-1.173 7.65a.85.85 0 01-.85.723H6.1zm-.842 1.7H2.987a2.813 2.813 0 01-2.83-2.436.849.849 0 01-.007-.114v-5.95c0-.038.003-.076.008-.114A2.813 2.813 0 012.988 7.8h1.71L7.873.655A.85.85 0 018.65.15a3.4 3.4 0 013.4 3.4V6.1h3.956a2.55 2.55 0 012.545 2.932l-1.173 7.65a2.55 2.55 0 01-2.545 2.168H5.258z\" fill=\"#000\"/>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});