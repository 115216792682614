define("liquid-fire/transitions/default", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultTransition;
  // This is what we run when no animation is asked for. It just sets
  // the newly-added element to visible (because we always start them
  // out invisible so that transitions can control their initial
  // appearance).
  function defaultTransition() {
    if (this.newElement) {
      this.newElement.style.visibility = '';
    }
    return _liquidFire.Promise.resolve();
  }
});