define("ember-semantic-ui-calendar/components/invoke-action-mixin", ["exports", "@ember/object/mixin", "@ember/debug", "@ember/object"], function (_exports, _mixin, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.strictInvokeAction = _exports.strictInvoke = _exports.invokeAction = _exports.invoke = _exports.default = _exports.InvokeActionMixin = void 0;
  const makeInvokeAction = function () {
    let {
      strict = false
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (object, actionName) {
      (false && !(typeof object === 'object') && (0, _debug.assert)('The first argument passed to invokeAction must be an object', typeof object === 'object'));
      let action;
      if (typeof actionName === 'string') {
        action = (0, _object.get)(object, actionName);
      } else if (typeof actionName === 'function') {
        action = actionName;
      } else {
        (false && !(false) && (0, _debug.assert)('The second argument passed to invokeAction must be a string as actionName or a function', false));
      }
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      if (typeof action === 'string') {
        object.sendAction(actionName, ...args);
      } else if (typeof action === 'function') {
        return action(...args);
      } else if (strict) {
        (false && !(false) && (0, _debug.assert)(`No invokable action ${actionName} was found`, false));
      }
    };
  };
  const getActions = object => {
    return object.actions ? object.actions : object._actions;
  };
  const makeInvoke = function () {
    let {
      strict = false
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (object, actionName) {
      let actions = getActions(object);
      let action = actions && actions[actionName];
      if (typeof action === 'function') {
        for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }
        return action.call(object, ...args);
      } else if (strict) {
        (false && !(false) && (0, _debug.assert)(`No invokable action ${actionName} was found`, false));
      }
    };
  };
  const invokeAction = makeInvokeAction();
  _exports.invokeAction = invokeAction;
  const strictInvokeAction = makeInvokeAction({
    strict: true
  });
  _exports.strictInvokeAction = strictInvokeAction;
  const invoke = makeInvoke();
  _exports.invoke = invoke;
  const strictInvoke = makeInvoke({
    strict: true
  });
  _exports.strictInvoke = strictInvoke;
  const InvokeActionMixin = _mixin.default.create({
    invokeAction() {
      return invokeAction(this, ...arguments);
    },
    strictInvokeAction() {
      return strictInvokeAction(this, ...arguments);
    },
    invoke() {
      return invoke(this, ...arguments);
    },
    strictInvoke() {
      return strictInvoke(this, ...arguments);
    }
  });
  _exports.InvokeActionMixin = InvokeActionMixin;
  var _default = invokeAction;
  _exports.default = _default;
});