define("semantic-ui-ember/helpers/map-value", ["exports", "ember", "ember-promise-utils/mixins/promise-resolver"], function (_exports, _ember, _promiseResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ember.default.Helper.extend(_promiseResolver.default, {
    compute(_ref) {
      let [action, maybePromise] = _ref;
      return this.resolvePromise(maybePromise, function (value) {
        return action(value);
      }, function () {
        this.recompute();
        return null;
      });
    }
  });
  _exports.default = _default;
});