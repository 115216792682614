define("ember-data-has-many-query/index", ["exports", "ember-data-has-many-query/mixins/model", "ember-data-has-many-query/mixins/rest-adapter", "ember-data-has-many-query/belongs-to-sticky"], function (_exports, _model, _restAdapter, _belongsToSticky) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ModelMixin", {
    enumerable: true,
    get: function () {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "RESTAdapterMixin", {
    enumerable: true,
    get: function () {
      return _restAdapter.default;
    }
  });
  Object.defineProperty(_exports, "belongsToSticky", {
    enumerable: true,
    get: function () {
      return _belongsToSticky.default;
    }
  });
  _exports.default = void 0;
  const RelationshipQuery = function () {
    this.ModelMixin = _model.default;
    this.RESTAdapterMixin = _restAdapter.default;
    this.belongsToSticky = _belongsToSticky.default;
  };
  const relationshipQuery = new RelationshipQuery();
  var _default = relationshipQuery;
  _exports.default = _default;
});