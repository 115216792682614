define("ember-velocity-mixin/mixins/velocity", ["exports", "ember", "jquery"], function (_exports, _ember, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global Element */

  const {
    assert,
    RSVP,
    Mixin
  } = _ember.default;

  // assert("Velocity.js must be installed to use this mixin.", jQuery.Velocity);

  _jquery.default.Velocity.Promise = RSVP.Promise;
  var _default = Mixin.create({
    /**
     * Retrieve & set css properties to element's style element.
     *
     * Retrieve value with this.css('width')
     * Set value with this.css('width', '100px')
     *
     * @returns {*}
     */
    css() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      if (!this._checkElement(args[0])) {
        // the first argument is not an element, get current view's element
        args.unshift(this.element);
      }
      if (args.length > 2) {
        // setting
        this.setCSSPropertyValue(...args);
      } else {
        // getting
        return this.getCSSPropertyValue(...args);
      }
    },
    /**
     * Animate style chance and return a promise
     * @param element
     * @returns {Promise}
     */
    animate(element) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }
      if (!this._checkElement(element)) {
        args.unshift(element);
        args.unshift(this.getDOMElement(this.element));
      } else {
        args.unshift(this.getDOMElement(element));
      }
      return _jquery.default.Velocity.animate(...args);
    },
    /**
     * Get CSS value for a property
     * @param {Ember.View|jQuery} element
     * @param {string} property
     * @returns {*}
     */
    getCSSPropertyValue(element, property) {
      return _jquery.default.Velocity.CSS.getPropertyValue(this.getDOMElement(element), property);
    },
    /**
     * Set CSS for a property
     * @param {Ember.View|jQuery} element
     * @param {string} property
     * @param {string} value
     * @returns {*}
     */
    setCSSPropertyValue(element, property, value) {
      return _jquery.default.Velocity.CSS.setPropertyValue(this.getDOMElement(element), property, value);
    },
    /**
     * Ensures that passes element is a jQuery element. When a view is passes, the element is returned.
     * @param {undefined|Object|JQuery} element
     * @returns {*}
     */
    getDOMElement(element) {
      assert('element must be an jQuery element or Component-like', this._checkElement(element));
      if (element.element) {
        element = element.element;
      } else if (element instanceof _jquery.default) {
        element = element[0];
      }
      return element;
    },
    /**
     * Check if element is an Ember View or jQuery Element
     * @param element
     * @returns {boolean}
     * @private
     */
    _checkElement(element) {
      return element.element || element instanceof Element || element instanceof _jquery.default;
    }
  });
  _exports.default = _default;
});