define("ember-svg-jar/inlined/allergy-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 14A7 7 0 107 0a7 7 0 000 14zM6.047 4a1 1 0 112 0v3a1 1 0 01-2 0V4zm1 5a1 1 0 100 2 1 1 0 000-2z\" fill=\"#000\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});