define("ember-ace/worker-manifest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ace/mode/json_worker": "https://star-fox-assets.food.ee/starfox/assets/ace/worker-json.js"
  };
  _exports.default = _default;
});