define("ember-model-validator/messages/hu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presenceMessage: 'nem lehet üres',
    absenceMessage: 'üresnek kell lennie',
    inclusionMessage: 'nem szerepel a listában',
    exclusionMessage: 'foglalt',
    // Numericality
    numericalityMessage: 'nem szám',
    numericalityOnlyIntegerMessage: 'egész számnak kell lennie',
    numericalityGreaterThanMessage: 'nagyobbnak kell lennie, mint {count}',
    numericalityGreaterThanOrEqualToMessage: 'nagyobbnak vagy egyenlőnek kell lennie, mint {count}',
    numericalityEqualToMessage: 'egyenlőnek kell lennie {count}-val/vel',
    numericalityLessThanMessage: 'kisebbnek kell lennie, mint {count}',
    numericalityLessThanOrEqualToMessage: 'kisebbnek vagy egyenlőnek kell lennie, mint {count}',
    numericalityOddMessage: 'páratlannak kell lennie',
    numericalityEvenMessage: 'párosnak kell lennie',
    mailMessage: 'érvénytelen e-mail-cím',
    formatMessage: 'hibás formátum',
    colorMessage: 'érvényes CSS hexa színkódnak kell lennie',
    subdomainMessage: 'érvényes aldomainnek kell lennie',
    acceptanceMessage: 'el kell fogadni',
    zipCodeMessage: 'érvénytelen irányítószám',
    URLMessage: 'érvényes url-nek kell lennie',
    wrongLengthMessage: 'nem megfelelő hosszúságú ({count} karakternek kell lennie)',
    tooShortMessage: 'túl rövid (minimum {count} karakter)',
    tooLongMessage: 'túl hosszú (maximum {count} karakter)',
    mustContainNumberMessage: 'tartalmaznia kell egy számot',
    mustContainSpecialMessage: 'tartalmaznia kell az alábbi különleges karakterek egyikét: {characters}',
    mustContainLowerMessage: 'tartalmaznia kell egy kisbetűs karaktert',
    mustContainCapitalMessage: 'tartalmaznia kell egy nagybetűs karaktert',
    customValidationMessage: 'érvénytelen',
    matchMessage: 'egyeznie kell {match}-val/vel',
    dateMessage: 'érvénytelen dátum',
    dateBeforeMessage: '{date} előtti dátumnak kell lennie',
    dateAfterMessage: '{date} utáni dátumnak kell lennie'
  };
  _exports.default = _default;
});