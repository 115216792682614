define("ember-svg-jar/inlined/heart-inactive-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.622 2.227A7.949 7.949 0 0120.157 0a7.95 7.95 0 015.534 2.227A7.538 7.538 0 0128 7.64a7.538 7.538 0 01-2.308 5.412L14.696 23.718a1 1 0 01-1.393 0L2.308 13.05a7.499 7.499 0 010-10.824c3.06-2.969 8.01-2.969 11.07 0l.622.604.622-.604zM20.157 2a5.95 5.95 0 00-4.142 1.663l-1.319 1.279a1 1 0 01-1.393 0l-1.318-1.28c-2.284-2.215-6-2.215-8.285 0a5.5 5.5 0 000 7.953L14 21.607l10.299-9.992A5.539 5.539 0 0026 7.64a5.538 5.538 0 00-1.701-3.976A5.95 5.95 0 0020.157 2z\" fill=\"#000\"/>",
    "attrs": {
      "width": "28",
      "height": "24",
      "viewBox": "0 0 28 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});