define("ember-svg-jar/inlined/lock-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.877 2.507a2.583 2.583 0 014.41 1.826V6a.75.75 0 001.5 0V4.333a4.083 4.083 0 10-8.167 0V6.25H2.333C1.183 6.25.25 7.183.25 8.333V13c0 1.15.933 2.083 2.083 2.083h9.334c1.15 0 2.083-.932 2.083-2.083V8.333c0-1.15-.933-2.083-2.083-2.083h-.547V4.333c0-.685.272-1.342.757-1.826zM10.37 7.75H2.333a.583.583 0 00-.583.583V13c0 .322.261.583.583.583h9.334A.583.583 0 0012.25 13V8.333a.583.583 0 00-.583-.583H10.37z\" fill=\"#000\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});