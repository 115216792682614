define("ember-svg-jar/inlined/star-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.888.54a1 1 0 00-1.776 0L7.261 6.033l-6.399.89A1 1 0 00.32 8.646l4.599 4.26-1.083 6.003a1 1 0 001.431 1.072L11 17.115l5.733 2.867a1 1 0 001.431-1.072l-1.083-6.003 4.599-4.26a1 1 0 00-.542-1.724l-6.399-.89L11.889.54z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "21",
      "viewBox": "0 0 22 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});