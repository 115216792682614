define("ember-svg-jar/inlined/tip_large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M40.309 4.828c-8.216 0-15.569 3.703-20.505 9.548a2.39 2.39 0 01-3.383.277 2.424 2.424 0 01-.276-3.402C21.955 4.37 30.625 0 40.31 0 57.812 0 72 14.273 72 31.879a31.86 31.86 0 01-5.85 18.457 2.423 2.423 0 01-.264.725c-5.97 10.7-16.314 18.177-28.567 18.885-11.257.65-21.572-4.565-28.715-13.13a2.424 2.424 0 01.297-3.401 2.39 2.39 0 013.381.298c6.307 7.563 15.221 11.964 24.762 11.413a27.7 27.7 0 008.238-1.759 31.75 31.75 0 01-4.974.39c-9.683 0-18.354-4.37-24.164-11.25a2.424 2.424 0 01.276-3.403 2.39 2.39 0 013.383.277c4.936 5.846 12.29 9.548 20.505 9.548C55.16 58.93 67.2 46.82 67.2 31.88c0-14.94-12.04-27.05-26.892-27.05zm2.396 7.967a2.407 2.407 0 00-2.4-2.414c-1.326 0-2.4 1.08-2.4 2.414v4.519h-1.907c-2.236 0-4.38.893-5.962 2.484a8.506 8.506 0 00-2.469 5.996c0 2.25.888 4.407 2.47 5.997a8.406 8.406 0 005.96 2.484h1.908v7.304h-7.938c-1.325 0-2.4 1.08-2.4 2.414a2.407 2.407 0 002.4 2.414h7.938v4.519a2.407 2.407 0 002.4 2.414c1.325 0 2.4-1.08 2.4-2.414v-4.519h1.908c2.236 0 4.38-.893 5.962-2.484a8.506 8.506 0 002.469-5.996c0-2.25-.888-4.406-2.47-5.997a8.406 8.406 0 00-5.961-2.483h-1.908v-7.305h6.216c1.325 0 2.4-1.08 2.4-2.414a2.407 2.407 0 00-2.4-2.414h-6.216v-4.519zm-4.8 9.347h-1.907a3.62 3.62 0 00-2.568 1.07 3.663 3.663 0 000 5.165 3.62 3.62 0 002.568 1.07h1.907v-7.305zm4.8 12.133v7.304h1.908a3.62 3.62 0 002.567-1.07 3.663 3.663 0 000-5.165 3.62 3.62 0 00-2.567-1.07h-1.908zm-31.69-13.477c1.326 0 2.4 1.081 2.4 2.415v6.252h6.217c1.325 0 2.4 1.08 2.4 2.414a2.407 2.407 0 01-2.4 2.414h-6.217v6.252a2.407 2.407 0 01-2.4 2.414c-1.325 0-2.4-1.081-2.4-2.414v-6.252H2.401c-1.326 0-2.4-1.08-2.4-2.414a2.407 2.407 0 012.4-2.414h6.214v-6.253a2.407 2.407 0 012.4-2.414z\" fill=\"#26282C\"/>",
    "attrs": {
      "width": "72",
      "height": "70",
      "viewBox": "0 0 72 70",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});