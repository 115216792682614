define("ember-svg-jar/inlined/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.071 1a1 1 0 112 0v6.071h6.071a1 1 0 110 2h-6.07v6.071a1 1 0 11-2 0v-6.07H1a1 1 0 010-2h6.071V1z\" fill=\"#000\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});