define("liquid-fire/helpers/lf-or", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lfOr = lfOr;
  function lfOr(params /*, hash*/) {
    return params.reduce((a, b) => a || b, false);
  }
  var _default = (0, _helper.helper)(lfOr);
  _exports.default = _default;
});