define("ember-svg-jar/inlined/left-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.588.293a1 1 0 011.414 1.414L3.737 5.972H15a1 1 0 110 2H3.737l4.265 4.264a1 1 0 11-1.414 1.414L.616 7.68a1 1 0 010-1.414L6.588.293z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});