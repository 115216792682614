define("ember-svg-jar/inlined/down-arrow-chevron-thin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.278 6.737c.14.14.331.22.53.22h.38a.77.77 0 00.53-.22l5.13-5.14a.5.5 0 000-.71l-.71-.71a.49.49 0 00-.7 0l-4.44 4.45-4.44-4.45a.5.5 0 00-.71 0l-.7.71a.5.5 0 000 .71l5.13 5.14z\" fill=\"#52575c\" fill-rule=\"evenodd\" vectornator:layerName=\"Untitled\"/>",
    "attrs": {
      "stroke-miterlimit": "10",
      "viewBox": "0 0 12 7",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:vectornator": "http://vectornator.io",
      "clip-rule": "evenodd",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
  _exports.default = _default;
});