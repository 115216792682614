define("ember-svg-jar/inlined/grid-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 0h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1zM11 0h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V1a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1zM1 5h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1zM11 5h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V6a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1zM6 0h2a1 1 0 011 1v2a1 1 0 01-1 1H6a1 1 0 01-1-1V1a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1H6a1 1 0 01-1-1v-2a1 1 0 011-1zM16 0h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V1a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1zM6 5h2a1 1 0 011 1v2a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1H6a1 1 0 01-1-1v-2a1 1 0 011-1zM16 5h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1V6a1 1 0 011-1zm0 10h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2a1 1 0 011-1z\" fill=\"#000\"/>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});