define("ember-svg-jar/inlined/recurring-thin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.375 5.5h-3.75a.375.375 0 01-.375-.375v-.217a.368.368 0 01.113-.27l1.335-1.335A5.198 5.198 0 007 1.75a5.25 5.25 0 105.25 5.603.375.375 0 01.375-.353h.75a.39.39 0 01.27.12.375.375 0 01.098.278 6.75 6.75 0 11-1.965-5.168l1.117-1.117A.367.367 0 0113.158 1h.217c.207 0 .375.168.375.375v3.75a.375.375 0 01-.375.375z\" fill=\"#52575C\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});