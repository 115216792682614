define("ember-data-has-many-query/mixins/rest-adapter", ["exports", "@ember/polyfills", "ember-copy", "@ember/object/mixin", "@ember/utils", "@ember/array", "ember-data-has-many-query/property-names"], function (_exports, _polyfills, _emberCopy, _mixin, _utils, _array, _propertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const evaluateFunctions = function (object, record) {
    if ((0, _array.isArray)(object)) {
      object.forEach(function (element) {
        if (typeof element === 'object') {
          evaluateFunctions(element, record);
        }
      });
    } else if (!(0, _utils.isNone)(object)) {
      Object.keys(object).forEach(function (key) {
        if (!object.hasOwnProperty(key)) {
          return;
        }
        const value = object[key];
        if (typeof value === 'function') {
          object[key] = value.apply(record);
        } else if (typeof value === 'object') {
          evaluateFunctions(value, record);
        }
      });
    }
  };

  /**
   * Mixin for DS.RESTAdapter.
   */
  var _default = _mixin.default.create({
    findHasMany: function (store, snapshot, url, relationship) {
      const id = snapshot.id;
      const type = snapshot.modelName;
      url = this.urlPrefix(url, this.buildURL(type, id, null, 'findHasMany'));
      const query = this.buildRelationshipQuery(snapshot, relationship);
      const options = {
        data: query
      };
      snapshot.record.set((0, _propertyNames.ajaxOptionsPropertyName)(relationship.key), options);
      return this.ajax(url, 'GET', options);
    },
    findBelongsTo: function (store, snapshot, url, relationship) {
      const id = snapshot.id;
      const type = snapshot.modelName;
      url = this.urlPrefix(url, this.buildURL(type, id, null, 'findBelongsTo'));
      const query = this.buildRelationshipQuery(snapshot, relationship);
      const options = {
        data: query
      };
      snapshot.record.set((0, _propertyNames.ajaxOptionsPropertyName)(relationship.key), options);
      return this.ajax(url, 'GET', options);
    },
    buildRelationshipQuery: function (snapshot, relationship) {
      let data = {};

      //add query parameters from the model mixin's query function
      const queryParams = snapshot.record.get((0, _propertyNames.queryParamPropertyName)(relationship.key));
      if (!(0, _utils.isEmpty)(queryParams)) {
        data = (0, _emberCopy.copy)(queryParams, true);
      }

      //add query parameters defined in the model itself by the 'parameters' option
      const relationshipParams = relationship.options.parameters;
      if (!(0, _utils.isEmpty)(relationshipParams)) {
        (0, _polyfills.assign)(data, relationshipParams);
      }

      //replace any functions in the data with their return value
      evaluateFunctions(data, snapshot.record);
      return data;
    },
    ajaxOptions: function () {
      const ajaxOptions = this._super(...arguments);
      const defaultBeforeSend = ajaxOptions.beforeSend || function () {};
      ajaxOptions.beforeSend = function (jqXHR) {
        defaultBeforeSend(...arguments);
        //store the jqXHR in the options object, which in turn is stored in the model itself, so the model mixin can abort it
        ajaxOptions.jqXHR = jqXHR;
      };
      return ajaxOptions;
    }
  });
  _exports.default = _default;
});