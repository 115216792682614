define("ember-svg-jar/inlined/right-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.412.293a1 1 0 10-1.414 1.414l4.265 4.265H1a1 1 0 100 2h11.262l-4.264 4.264a1 1 0 101.414 1.414l5.972-5.971a1 1 0 000-1.414L9.412.293z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});