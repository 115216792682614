define("ember-data-model-fragments/ext", ["exports", "@ember/debug", "@ember-data/store", "@ember-data/model", "ember-data/-private", "@ember-data/serializer/json", "ember-data-model-fragments/states", "ember-data-model-fragments/record-data", "ember-data-model-fragments/fragment", "@ember/utils", "@ember/object", "@ember/application", "@ember/polyfills"], function (_exports, _debug, _store, _model, _private, _json, _states, _recordData, _fragment, _utils, _object, _application, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "JSONSerializer", {
    enumerable: true,
    get: function () {
      return _json.default;
    }
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function () {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function () {
      return _store.default;
    }
  });
  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports

  function serializerForFragment(owner, normalizedModelName) {
    let serializer = owner.lookup(`serializer:${normalizedModelName}`);
    if (serializer !== undefined) {
      return serializer;
    }

    // no serializer found for the specific model, fallback and check for application serializer
    serializer = owner.lookup('serializer:-fragment');
    if (serializer !== undefined) {
      return serializer;
    }

    // final fallback, no model specific serializer, no application serializer, no
    // `serializer` property on store: use json-api serializer
    serializer = owner.lookup('serializer:-default');
    return serializer;
  }
  /**
    @module ember-data-model-fragments
  */

  const InternalModelPrototype = _private.InternalModel.prototype;
  const RecordDataPrototype = _private.RecordData.prototype;
  (0, _polyfills.assign)(RecordDataPrototype, {
    eachFragmentKey(fn) {
      this._fragments = this._fragments || Object.create({});
      Object.keys(this._fragments).forEach(fn);
    },
    eachFragmentKeyValue(fn) {
      this.eachFragmentKey(key => {
        const value = this.getFragment(key);
        if (value) {
          fn(key, value);
        }
      });
    },
    getOwner() {
      return this._owner;
    },
    setOwner(value) {
      this._owner = value;
    },
    setName(value) {
      this._name = value;
    },
    getName() {
      return this._name;
    },
    getFragment(name) {
      this._fragments = this._fragments || Object.create({});
      return this._fragments[name];
    },
    setFragment(name, fragment) {
      this._fragments = this._fragments || Object.create({});
      this._fragments[name] = fragment;
      return this._fragments[name];
    },
    didCommit(data) {
      if (this._attributes) {
        // willCommit was never called
        this._inFlightAttributes = this._attributes;
        this._attributes = null;
      }
      this._isNew = false;
      if (data) {
        if (data.relationships) {
          this._setupRelationships(data);
        }
        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = (0, _private.coerceId)(data.id);
        }
        data = data.attributes;

        // Notify fragments that the record was committed
        this.eachFragmentKeyValue((key, fragment) => fragment._didCommit(data[key]));
      } else {
        this.eachFragmentKeyValue((key, fragment) => fragment._didCommit());
      }
      const changedKeys = this._changedKeys(data);
      (0, _polyfills.assign)(this._data, this._inFlightAttributes, data);
      this._inFlightAttributes = null;
      this._updateChangedAttributes();
      return changedKeys;
    }
  });

  /**
    @class Store
    @namespace DS
  */
  _store.default.reopen({
    createRecordDataFor(type, id, lid, storeWrapper) {
      let identifier;
      if (false) {
        throw new Error('This version of Ember Data Model Fragments is incompatible with Ember Data Versions below 3.13. See matrix at https://github.com/lytics/ember-data-model-fragments#compatibility for details.');
      }
      if (true) {
        identifier = this.identifierCache.getOrCreateRecordIdentifier({
          type,
          id,
          lid
        });
      } else {
        identifier = {
          type,
          id,
          clientId: lid
        };
      }
      return new _recordData.default(identifier, storeWrapper);
    },
    /**
      Create a new fragment that does not yet have an owner record.
      The properties passed to this method are set on the newly created
      fragment.
       To create a new instance of the `name` fragment:
       ```js
      store.createFragment('name', {
        first: 'Alex',
        last: 'Routé'
      });
      ```
       @method createRecord
      @param {String} type
      @param {Object} properties a hash of properties to set on the
        newly created fragment.
      @return {MF.Fragment} fragment
    */
    createFragment(modelName, props) {
      (false && !(this.isFragment(modelName)) && (0, _debug.assert)(`The '${modelName}' model must be a subclass of MF.Fragment`, this.isFragment(modelName)));
      let internalModel;
      if (true) {
        const identifier = this.identifierCache.createIdentifierForNewRecord({
          type: modelName
        });
        internalModel = this._internalModelForResource(identifier);
      } else {
        let identifier = {
          type: modelName,
          id: `${Math.random()}`,
          lid: `${Math.random()}`
        };
        internalModel = this._internalModelForResource(identifier);
      }

      // Re-wire the internal model to use the fragment state machine
      internalModel.currentState = _states.default.empty;
      internalModel._recordData._name = null;
      internalModel._recordData._owner = null;
      internalModel.send('loadedData');
      let fragment = internalModel.getRecord();
      if (props) {
        fragment.setProperties(props);
      }

      // invoke the ready callback ( to mimic DS.Model behaviour )
      fragment.trigger('ready');

      // Add brand to reduce usages of `instanceof`
      fragment._isFragment = true;
      return fragment;
    },
    /**
      Returns true if the modelName is a fragment, false if not
       @method isFragment
      @private
      @param {String} the modelName to check if a fragment
      @return {boolean}
    */
    isFragment(modelName) {
      if (modelName === 'application' || modelName === '-default') {
        return false;
      }
      let type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    },
    serializerFor(modelName) {
      // this assertion is cargo-culted from ember-data TODO: update comment
      (false && !((0, _utils.isPresent)(modelName)) && (0, _debug.assert)('You need to pass a model name to the store\'s serializerFor method', (0, _utils.isPresent)(modelName)));
      (false && !(typeof modelName === 'string') && (0, _debug.assert)(`Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));
      let owner = (0, _application.getOwner)(this);
      let normalizedModelName = (0, _private.normalizeModelName)(modelName);
      if (this.isFragment(normalizedModelName)) {
        return serializerForFragment(owner, normalizedModelName);
      } else {
        return this._super(...arguments);
      }
    }
  });

  /**
    @class Model
    @namespace DS
    */
  _model.default.reopen({
    willDestroy() {
      this._super(...arguments);
      let internalModel = (0, _fragment.internalModelFor)(this);

      // destroy the current state
      internalModel._recordData.resetFragments();
    }
  });
  _model.default.reopenClass({
    fields: (0, _object.computed)(function () {
      let map = new Map();
      this.eachComputedProperty((name, meta) => {
        if (meta.isFragment) {
          map.set(name, 'fragment');
        } else if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });
      return map;
    }).readOnly()
  });

  // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result
  function decorateMethod(obj, name, fn) {
    let originalFn = obj[name];
    obj[name] = function () {
      let value = originalFn.apply(this, arguments);
      return fn.call(this, value, arguments);
    };
  }

  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */
  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    let attrs = snapshot._attributes;
    Object.keys(attrs).forEach(key => {
      let attr = attrs[key];
      // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer
      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });
    return snapshot;
  });

  /**
    @class JSONSerializer
    @namespace DS
  */
  _json.default.reopen({
    /**
      Enables fragment properties to have custom transforms based on the fragment
      type, so that deserialization does not have to happen on the fly
       @method transformFor
      @private
    */
    transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') !== 0) {
        return this._super(...arguments);
      }
      const owner = (0, _application.getOwner)(this);
      const containerKey = `transform:${attributeType}`;
      if (!owner.hasRegistration(containerKey)) {
        const match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
        const transformName = match[1];
        const type = match[2];
        const polymorphicTypeProp = match[3];
        let transformClass = owner.factoryFor(`transform:${transformName}`);
        transformClass = transformClass && transformClass.class;
        transformClass = transformClass.extend({
          type,
          polymorphicTypeProp,
          store: this.store
        });
        owner.register(containerKey, transformClass);
      }
      return owner.lookup(containerKey);
    }
  });
});