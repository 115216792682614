define("semantic-ui-ember/templates/components/ui-sticky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zlQoiOnw",
    "block": "[[[18,1,[[28,[37,1],[[30,0],\"execute\"],null]]]],[\"&default\"],false,[\"yield\",\"action\"]]",
    "moduleName": "semantic-ui-ember/templates/components/ui-sticky.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});