define("ember-svg-jar/inlined/checkmark-dish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.25 56.752V149M5 5.502v41a10.25 10.25 0 0020.5 0v-41M112.625 53.779A49.045 49.045 0 0076.75 38.172 49.037 49.037 0 0040.875 53.78M40.875 121.224a49.04 49.04 0 0055.436 11.536 49.047 49.047 0 0016.314-11.536M103.078 71.121l-35.132 35.058-15.97-15.936M138.088 149.5v-52m0 0V13c-.333-2.667.1-8 4.5-8 5.5 0 7 7.5 8.5 12.5s7.5 14 7.5 74.5c0 5-4.5 5.5-7.5 5.5h-13z\" stroke=\"#A32A2A\" stroke-width=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "164",
      "height": "155",
      "viewBox": "0 0 164 155",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});