define("ember-svg-jar/inlined/delivery-atrestaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.75 1A.75.75 0 014.5.25H9a.75.75 0 01.75.75v4.907c0 .39-.215.747-.56.929-.314.17-.69.173-1.006.012l-1.428-.647-1.46.69a1.085 1.085 0 01-1.009-.031 1.05 1.05 0 01-.537-.916V1zm1.5.75v3.504l1.18-.557a.75.75 0 01.63-.005l1.19.54V1.75h-3zm-.615 3.794a.46.46 0 01.008-.004l-.008.004z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.55 1.75a.05.05 0 00-.05.05v10.4c0 .028.022.05.05.05h8.2a.75.75 0 010 1.5h-8.2A1.55 1.55 0 010 12.2V1.8C0 .944.694.25 1.55.25h10.4a.75.75 0 010 1.5H1.55zM18 1.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM14.25 4a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 13.75a6 6 0 0112 0V16a.75.75 0 01-.75.75h-1.571l-.683 6.825a.75.75 0 01-.746.675h-4.5a.75.75 0 01-.746-.675l-.683-6.825H12.75A.75.75 0 0112 16v-2.25zm6-4.5a4.5 4.5 0 00-4.5 4.5v1.5H15a.75.75 0 01.746.675l.683 6.825h3.142l.683-6.825A.75.75 0 0121 15.25h1.5v-1.5a4.5 4.5 0 00-4.5-4.5zM12 4a.75.75 0 01.75-.75H21a.75.75 0 010 1.5h-8.25A.75.75 0 0112 4z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});