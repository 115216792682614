define("ember-stripe-service-foodee/utils/stripe-mock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    setPublishableKey() {},
    card: {
      cardType() {},
      validateCardNumber() {},
      validateCVC() {},
      validateExpiry() {},
      createToken(card, cb) {
        cb('ok', {
          id: 'mocked'
        });
      }
    },
    bankAccount: {
      createToken(backAccount, cb) {
        cb('ok', {
          id: 'mocked'
        });
      }
    },
    piiData: {
      createToken(piiData, cb) {
        cb('ok', {
          id: 'mocked'
        });
      }
    }
  };
  _exports.default = _default;
});