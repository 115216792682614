define("ember-svg-jar/inlined/locked-no-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.5 3.5a2 2 0 114 0V5h-4V3.5zM2.5 5V3.5a3 3 0 016 0V5H9a1.5 1.5 0 011.5 1.5V10A1.5 1.5 0 019 11.5H2A1.5 1.5 0 01.5 10V6.5A1.5 1.5 0 012 5h.5zM8 6H2a.5.5 0 00-.5.5V10a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V6.5A.5.5 0 009 6H8z\" fill=\"#000\"/>",
    "attrs": {
      "width": "11",
      "height": "12",
      "viewBox": "0 0 11 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});