define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 .35a7.65 7.65 0 100 15.3A7.65 7.65 0 008 .35zM1.65 8a6.35 6.35 0 1112.7 0 6.35 6.35 0 01-12.7 0zm7-4.2a.65.65 0 00-1.3 0V8a.65.65 0 00.19.46l2.1 2.1a.65.65 0 00.92-.92L8.65 7.73V3.8z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});