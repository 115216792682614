define("ember-svg-jar/inlined/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.5 6.5V2a.5.5 0 00-.675-.467l-5.5 2.847A.5.5 0 005 4.846V7.5M10 4.5h1.5M9 6.5h2.5M1.5 9.5H3M1.5 11.5H4M6.5 3.77V1.5\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.5 16.5v-8a1 1 0 00-1-1h-3a1 1 0 00-1 1v8h8M13.5 11.25a.25.25 0 11-.25.25v0a.25.25 0 01.25-.25\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M13.5 8.5a3 3 0 013 3c0 1.28-1.792 3.767-2.606 4.808a.5.5 0 01-.788 0c-.814-1.041-2.606-3.527-2.606-4.808a3 3 0 013-3z\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});