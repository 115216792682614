define("ember-svg-jar/inlined/list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 0h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm0 6h2a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1V7a1 1 0 011-1zm2 6H1a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 00-1-1zM7 1h10a1 1 0 110 2H7a1 1 0 010-2zm10 6H7a1 1 0 000 2h10a1 1 0 100-2zM7 13h10a1 1 0 110 2H7a1 1 0 110-2z\" fill=\"#000\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});