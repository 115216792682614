define("ember-svg-jar/inlined/union", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.004.5A2.503 2.503 0 00.5 3v19c0 1.383 1.124 2.5 2.504 2.5h16.117a2.503 2.503 0 002.504-2.5V3c0-1.383-1.124-2.5-2.504-2.5H3.004zM2.578 3c0-.23.189-.422.426-.422h16.117c.237 0 .426.192.426.423V22c0 .23-.189.422-.426.422H3.004A.425.425 0 012.578 22V3zm8.493 2.5a4 4 0 100 8 4 4 0 000-8zm-2.168 4a2.169 2.169 0 112.168 2.17A2.169 2.169 0 018.903 9.5zm9.558 9.7c-3.084-6.168-11.515-6.312-14.958-.034a1.04 1.04 0 001.822.999c2.666-4.861 8.97-4.65 11.277-.035a1.039 1.039 0 001.859-.93z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "25",
      "viewBox": "0 0 22 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});