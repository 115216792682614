define("ember-svg-jar/inlined/star-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.064.268a.487.487 0 01.87 0L5.949 2.31c.07.142.208.24.365.263l2.272.328a.482.482 0 01.39.325.473.473 0 01-.12.489l-1.65 1.6a.478.478 0 00-.138.42l.389 2.256a.474.474 0 01-.194.467.49.49 0 01-.511.035l-2.024-1.06a.492.492 0 00-.454 0l-2.024 1.06a.49.49 0 01-.511-.035.474.474 0 01-.194-.467l.388-2.255a.475.475 0 00-.137-.421l-1.65-1.6a.473.473 0 01-.121-.49.48.48 0 01.39-.324l2.272-.328a.489.489 0 00.366-.263L4.064.268z\" fill=\"#000\"/>",
    "attrs": {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});