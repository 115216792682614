define("ember-svg-jar/inlined/add-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.918.65a.65.65 0 111.3 0v2.268h2.267a.65.65 0 010 1.3H4.218v2.267a.65.65 0 01-1.3 0V4.218H.65a.65.65 0 010-1.3h2.268V.65z\" fill=\"#000\"/>",
    "attrs": {
      "width": "8",
      "height": "8",
      "viewBox": "0 0 8 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});