define("ember-svg-jar/inlined/order-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 4.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM1.5 6a3 3 0 116 0 3 3 0 01-6 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 13.5A4.5 4.5 0 014.5 9a.75.75 0 010 1.5 3 3 0 00-3 3V15h.75a.75.75 0 01.744.657L3.662 21H6a.75.75 0 010 1.5H3a.75.75 0 01-.744-.657L1.588 16.5H.75a.75.75 0 01-.75-.75V13.5zM19.5 4.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3 1.5a3 3 0 116 0 3 3 0 01-6 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.75 9.75A.75.75 0 0119.5 9a4.5 4.5 0 014.5 4.5v2.25a.75.75 0 01-.75.75h-.838l-.668 5.343A.75.75 0 0121 22.5h-3a.75.75 0 010-1.5h2.338l.668-5.343A.75.75 0 0121.75 15h.75v-1.5a3 3 0 00-3-3 .75.75 0 01-.75-.75zM12 1.5A2.25 2.25 0 1012 6a2.25 2.25 0 000-4.5zM8.25 3.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 13.5a6 6 0 0112 0v2.25a.75.75 0 01-.75.75h-1.571l-.683 6.825a.75.75 0 01-.746.675h-4.5a.75.75 0 01-.746-.675L8.32 16.5H6.75a.75.75 0 01-.75-.75V13.5zM12 9a4.5 4.5 0 00-4.5 4.5V15H9a.75.75 0 01.746.675l.683 6.825h3.142l.683-6.825A.75.75 0 0115 15h1.5v-1.5A4.5 4.5 0 0012 9z\" fill=\"#000\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});