define("liquid-fire/transitions/fade", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fade;
  // BEGIN-SNIPPET fade-definition

  function fade() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let firstStep;
    let outOpts = opts;
    let fadingElement = findFadingElement(this);
    if (fadingElement) {
      // We still have some older version that is in the process of
      // fading out, so out first step is waiting for it to finish.
      firstStep = (0, _liquidFire.finish)(fadingElement, 'fade-out');
    } else {
      if ((0, _liquidFire.isAnimating)(this.oldElement, 'fade-in')) {
        // if the previous view is partially faded in, scale its
        // fade-out duration appropriately.
        outOpts = {
          duration: (0, _liquidFire.timeSpent)(this.oldElement, 'fade-in')
        };
      }
      (0, _liquidFire.stop)(this.oldElement);
      firstStep = (0, _liquidFire.animate)(this.oldElement, {
        opacity: 0
      }, outOpts, 'fade-out');
    }
    return firstStep.then(() => {
      return (0, _liquidFire.animate)(this.newElement, {
        opacity: [opts.maxOpacity || 1, 0]
      }, opts, 'fade-in');
    });
  }
  function findFadingElement(context) {
    for (let i = 0; i < context.older.length; i++) {
      let entry = context.older[i];
      if ((0, _liquidFire.isAnimating)(entry.element, 'fade-out')) {
        return entry.element;
      }
    }
    if ((0, _liquidFire.isAnimating)(context.oldElement, 'fade-out')) {
      return context.oldElement;
    }
  }
  // END-SNIPPET
});