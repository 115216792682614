define("ember-promise-utils/utils/get-promise-content", ["exports", "@ember/object/promise-proxy-mixin", "rsvp"], function (_exports, _promiseProxyMixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPromiseContent;
  /**
   * It's assumed if you call this method, it was previously checked that it was a promise
   * and is fullfilled
   *
   * @param promise
   * @returns {Object} contents of the promise
   */
  function getPromiseContent(promise) {
    if (_promiseProxyMixin.default.detect(promise)) {
      return promise.get('content');
    }
    if (promise instanceof _rsvp.Promise) {
      return promise._result;
    }

    // Only can get the content for one of the two above
    return null;
  }
});