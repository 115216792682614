define("liquid-fire/templates/components/liquid-if", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.containerless}}
    {{! Why are we using inline-ifs here? Because we want to force
      conversion to a boolean while respecting Ember's built in `if`
      semantics.
  
      Why not just defer the conversion until the real block `if` below?
      Because we don't want liquid-versions to treat distinct values of
      the same truthiness as distinct.
    }}
  
    <LiquidVersions
      @value={{if this.inverted (if this.predicate false true) (if this.predicate true false)}}
      @matchContext={{hash helperName=this.helperName}}
      @use={{this.use}}
      @rules={{this.rules}}
      @renderWhenFalse={{has-block "inverse"}}
      @class={{this.class}} as |valueVersion|
    >
      {{#if valueVersion}}
        {{yield}}
      {{else}}
        {{yield to="inverse"}}
      {{/if}}
    </LiquidVersions>
  {{else}}
    <LiquidContainer
      @id={{this.containerId}}
      @class={{this.class}}
      @growDuration={{this.growDuration}}
      @growPixelsPerSecond={{this.growPixelsPerSecond}}
      @growEasing={{this.growEasing}}
      @shrinkDelay={{this.shrinkDelay}}
      @growDelay={{this.growDelay}}
      @enableGrowth={{this.enableGrowth}} as |container|
    >
      <LiquidVersions
        @value={{if this.inverted (if this.predicate false true) (if this.predicate true false)}}
        @notify={{container}}
        @matchContext={{hash helperName=this.helperName}}
        @use={{this.use}}
        @rules={{this.rules}}
        @renderWhenFalse={{has-block "inverse"}} as |valueVersion|
      >
        {{#if valueVersion}}
          {{yield}}
        {{else}}
          {{yield to="inverse"}}
        {{/if}}
      </LiquidVersions>
    </LiquidContainer>
  {{/if}}
  */
  {
    "id": "zAJY/l8O",
    "block": "[[[41,[30,0,[\"containerless\"]],[[[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@matchContext\",\"@use\",\"@rules\",\"@renderWhenFalse\",\"@class\"],[[52,[30,0,[\"inverted\"]],[52,[30,0,[\"predicate\"]],false,true],[52,[30,0,[\"predicate\"]],true,false]],[28,[37,2],null,[[\"helperName\"],[[30,0,[\"helperName\"]]]]],[30,0,[\"use\"]],[30,0,[\"rules\"]],[48,[30,4]],[30,0,[\"class\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[1]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,5],null,[[\"@id\",\"@class\",\"@growDuration\",\"@growPixelsPerSecond\",\"@growEasing\",\"@shrinkDelay\",\"@growDelay\",\"@enableGrowth\"],[[30,0,[\"containerId\"]],[30,0,[\"class\"]],[30,0,[\"growDuration\"]],[30,0,[\"growPixelsPerSecond\"]],[30,0,[\"growEasing\"]],[30,0,[\"shrinkDelay\"]],[30,0,[\"growDelay\"]],[30,0,[\"enableGrowth\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@value\",\"@notify\",\"@matchContext\",\"@use\",\"@rules\",\"@renderWhenFalse\"],[[52,[30,0,[\"inverted\"]],[52,[30,0,[\"predicate\"]],false,true],[52,[30,0,[\"predicate\"]],true,false]],[30,2],[28,[37,2],null,[[\"helperName\"],[[30,0,[\"helperName\"]]]]],[30,0,[\"use\"]],[30,0,[\"rules\"]],[48,[30,4]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[18,5,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[18,4,null],[1,\"\\n\"]],[]]],[1,\"    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]]]],[\"valueVersion\",\"container\",\"valueVersion\",\"&else\",\"&default\"],false,[\"if\",\"liquid-versions\",\"hash\",\"has-block\",\"yield\",\"liquid-container\"]]",
    "moduleName": "liquid-fire/templates/components/liquid-if.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});