define("ember-svg-jar/inlined/meal-plan-adhoc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26 15.449a1.5 1.5 0 013 0v6h6a1.5 1.5 0 110 3h-6v6a1.5 1.5 0 11-3 0v-6h-6a1.5 1.5 0 010-3h6v-6z\" fill=\"#D84B44\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M50.39 1.767a2.336 2.336 0 00-2.753-1.38 2.442 2.442 0 00-1.827 2.4V42.27a1.5 1.5 0 003 0v-9.69h3.828a1.5 1.5 0 001.5-1.5c0-17.266-1.913-25.048-3.748-29.314zm.743 27.815H48.81V6.344c1.182 4.237 2.242 11.18 2.323 23.238zM13.125 8.31a1.5 1.5 0 102.122 2.122c6.696-6.697 17.554-6.697 24.25 0A1.5 1.5 0 1041.62 8.31C33.75.442 20.994.442 13.125 8.31zm2.122 26.373a1.5 1.5 0 10-2.122 2.12c7.869 7.87 20.626 7.87 28.494 0a1.5 1.5 0 10-2.121-2.12c-6.697 6.696-17.555 6.696-24.251 0zM6.592 22.7a4.474 4.474 0 002.96-4.21V2.142a1.5 1.5 0 00-3 0v16.349c0 .668-.446 1.232-1.056 1.412a1.501 1.501 0 00-.821.004 1.473 1.473 0 01-1.069-1.416V2.142a1.5 1.5 0 00-3 0v16.349a4.474 4.474 0 002.986 4.22v20.303a1.5 1.5 0 003 0V22.7z\" fill=\"#636A77\"/>",
    "attrs": {
      "width": "55",
      "height": "45",
      "viewBox": "0 0 55 45",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});