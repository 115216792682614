define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.833 0a6.833 6.833 0 104.073 12.32l4.387 4.387a1 1 0 001.414-1.414l-4.386-4.387A6.833 6.833 0 006.834 0zM2 6.833a4.833 4.833 0 119.667 0 4.833 4.833 0 01-9.667 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});