define("ember-svg-jar/inlined/thumbsdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.88 2.766a1.113 1.113 0 00-1.115-.916H14.33V9.5h1.434c.549.01 1.02-.38 1.116-.915v-5.82zm-4.25-.916v8.32l-3.067 6.9a1.7 1.7 0 01-1.183-1.62v-3.4a.85.85 0 00-.85-.85H2.71a.85.85 0 01-.85-.977l1.173-7.65a.85.85 0 01.85-.723h8.747zm.842-1.7h2.271a2.813 2.813 0 012.83 2.436.851.851 0 01.007.114v5.95a.851.851 0 01-.008.114 2.814 2.814 0 01-2.83 2.436h-1.71l-3.175 7.145a.85.85 0 01-.777.505 3.4 3.4 0 01-3.4-3.4V12.9H2.724A2.55 2.55 0 01.179 9.968l1.173-7.65A2.55 2.55 0 013.897.15h9.575z\" fill=\"#000\"/>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});