define("ember-svg-jar/inlined/recurring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.223 2.8c2.404-1.381 5.397-.946 7.35 1.09l.022.022L14.724 6H12a1 1 0 100 2h5a1 1 0 001-1v-.167V2a1 1 0 10-2 0v2.45l-1.994-1.955c-2.58-2.68-6.57-3.273-9.78-1.43C1.026 2.904-.595 6.703.202 10.37c.799 3.672 3.846 6.393 7.52 6.616 2.728.166 5.275-1.08 6.897-3.19a1 1 0 00-1.586-1.218c-1.238 1.61-3.16 2.536-5.19 2.412-2.731-.166-5.068-2.2-5.686-5.045-.62-2.85.65-5.757 3.067-7.144z\" fill=\"#000\"/>",
    "attrs": {
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});