define("semantic-ui-ember/components/ui-sidebar", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-sidebar"], function (_exports, _ember, _base, _uiSidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ember.default.Component.extend(_base.default, {
    layout: _uiSidebar.default,
    module: 'sidebar',
    classNames: ['ui', 'sidebar']
  });
  _exports.default = _default;
});