define("ember-svg-jar/inlined/left-arrow-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.18 1.073a1.5 1.5 0 01-.253 2.106l-5.5 4.32 5.5 4.321a1.5 1.5 0 01-1.854 2.36l-7-5.5a1.5 1.5 0 010-2.36l7-5.5a1.5 1.5 0 012.107.253z\" fill=\"#000\"/>",
    "attrs": {
      "width": "11",
      "height": "15",
      "viewBox": "0 0 11 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});