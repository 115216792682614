define("ember-svg-jar/inlined/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.407 3.09a1.093 1.093 0 112.186 0v.099a2.722 2.722 0 001.646 2.49 2.723 2.723 0 002.998-.547l.07-.071a1.093 1.093 0 011.548 0 1.094 1.094 0 010 1.548l-.063.063-.007.008c-.724.74-.961 1.82-.63 2.786v.102l.08.189a2.722 2.722 0 002.492 1.65h.182a1.093 1.093 0 010 2.186h-.098a2.723 2.723 0 00-2.49 1.646 2.723 2.723 0 00.547 2.998l.071.07a1.093 1.093 0 010 1.548 1.093 1.093 0 01-1.548 0l-.063-.063-.008-.007a2.722 2.722 0 00-2.998-.547 2.723 2.723 0 00-1.645 2.49v.181a1.093 1.093 0 11-2.187 0V21.792a2.722 2.722 0 00-1.753-2.48 2.722 2.722 0 00-2.974.556l-.07.071a1.093 1.093 0 01-1.548 0 1.093 1.093 0 010-1.548l.063-.063.007-.008a2.722 2.722 0 00.547-2.998 2.722 2.722 0 00-2.49-1.645h-.181a1.093 1.093 0 110-2.187H3.208a2.723 2.723 0 002.48-1.753 2.723 2.723 0 00-.556-2.974l-.071-.07a1.093 1.093 0 010-1.548 1.093 1.093 0 011.548 0l.063.063.008.007c.74.724 1.82.961 2.786.63h.102l.189-.08a2.722 2.722 0 001.65-2.492V3.09zM12.5.003a3.088 3.088 0 00-3.088 3.089v.175a.728.728 0 01-.3.584H9.07l-.193.085a.728.728 0 01-.8-.143l-.057-.058a3.088 3.088 0 10-4.369 4.37l.058.057a.727.727 0 01.142.8l-.013.029-.01.03a.728.728 0 01-.657.475h-.08a3.088 3.088 0 000 6.177h.175c.29.002.55.175.664.44l.005.01c.12.271.063.587-.143.8l-.057.058a3.088 3.088 0 104.369 4.369l.058-.058a.727.727 0 01.799-.142l.029.013.03.01a.727.727 0 01.475.657v.08a3.088 3.088 0 006.177 0v-.175a.728.728 0 01.441-.664l.01-.005a.728.728 0 01.8.143l.057.058a3.089 3.089 0 104.369-4.37l-.058-.058a.727.727 0 01-.142-.799l.004-.01a.727.727 0 01.664-.44h.092a3.088 3.088 0 000-6.177h-.175a.728.728 0 01-.584-.3V9.07l-.085-.193a.728.728 0 01.143-.8l.057-.057a3.089 3.089 0 10-4.369-4.37l-.058.059a.728.728 0 01-.799.142l-.01-.004a.727.727 0 01-.44-.664V3.09A3.088 3.088 0 0012.5.002zm8.85 21.264l-.706-.706.705.706zm-8.85-12.9a4.134 4.134 0 100 8.268 4.134 4.134 0 000-8.268zM10.361 12.5a2.139 2.139 0 114.278 0 2.139 2.139 0 01-4.278 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});