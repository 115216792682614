define("ember-ace/utils/completion-manager", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Supports Ace's interface for supplying completion information.
   *
   * @private
   * @class CompletionManager
   */
  class CompletionManager {
    constructor(_ref) {
      let {
        suggestCompletions,
        renderCompletionTooltip
      } = _ref;
      this._suggestCompletions = suggestCompletions;
      this._renderCompletionTooltip = renderCompletionTooltip;
    }

    // Called by Ace when
    getCompletions(editor, session, position, prefix, callback) {
      const suggestCompletions = this._suggestCompletions;
      if (!suggestCompletions) return callback(null, []);
      let promise;
      try {
        promise = (0, _runloop.run)(() => suggestCompletions(editor, session, position, prefix));
      } catch (error) {
        promise = _rsvp.default.reject(error);
      }
      _rsvp.default.resolve(promise).then(results => callback(null, results)).catch(error => callback(error));
    }
    getDocTooltip(result) {
      result.docHTML = this._renderCompletionTooltip.call(null, result);
    }
  }
  _exports.default = CompletionManager;
});