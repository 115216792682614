define("ember-svg-jar/inlined/nav-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.917 2.583H3.583c-.736 0-1.333.597-1.333 1.334v9.333c0 .736.597 1.334 1.333 1.334h9.334c.736 0 1.333-.598 1.333-1.334V3.917c0-.737-.597-1.333-1.333-1.333zM10.917 1.25v2.667M5.583 1.25v2.667M2.25 6.583h12\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});