define("ember-arg-types/decorator", ["exports", "ember-arg-types/-private/is-element-descriptor", "ember-arg-types/-private/throw-console-error", "prop-types", "ember-get-config", "@ember/utils", "ember-arg-types/-private/closest-string"], function (_exports, _isElementDescriptor, _throwConsoleError, _propTypes, _emberGetConfig, _utils, _closestString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;
  _exports.forbidExtraArgs = forbidExtraArgs;
  const REGISTERED_ARGS = Symbol('args');
  const INTERCEPT_CLASS = 'ForbidExtraArgsIntercept';
  function shouldThrowErrors() {
    const throwErrors = _emberGetConfig.default['ember-arg-types']?.throwErrors;
    return (0, _utils.isNone)(throwErrors) ? true : throwErrors;
  }
  function getClassName(instance) {
    return instance.constructor.name === INTERCEPT_CLASS // if the current class is this override
    ? Object.getPrototypeOf(instance.constructor).name // get parent class name
    : instance.constructor.name; // use current class name
  }

  function createGetter(target, key, descriptor, validator) {
    const defaultInitializer = descriptor.initializer || descriptor.get || (() => undefined);
    return {
      get() {
        const argValue = this.args[key];
        const returnValue = argValue !== undefined ? argValue : defaultInitializer.call(this);
        return returnValue;
      }
    };
  }
  function arg() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if ((0, _isElementDescriptor.default)(...args)) {
      return createGetter(...args);
    }
    const [validator] = args;
    return function argument() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      return createGetter(...[...args, validator]);
    };
  }
  function forbidExtraArgs(target) {
    let returnClass = target;

    // only sublcass in debug mode

    return returnClass;
  }
});