define("ember-arg-types/-private/closest-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.closest = void 0;
  /* eslint-disable no-inner-declarations */

  let closest;
  _exports.closest = closest;
  {
    _exports.closest = closest = () => {
      throw new Error('closest() is not available in production');
    };
  }
});