define("ember-model-validator/messages/tr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    presenceMessage: 'boş bırakılamaz',
    absenceMessage: 'boş bırakılmalı',
    inclusionMessage: 'listeye dahil değil',
    exclusionMessage: 'ayrılmış',
    numericalityMessage: 'bir sayı değil',
    numericalityOnlyIntegerMessage: 'bir tamsayı olmalı',
    numericalityGreaterThanMessage: "{count}'den büyük olmalı",
    numericalityGreaterThanOrEqualToMessage: "{count}'e eşit veya daha büyük olmalı",
    numericalityEqualToMessage: "{count}'e eşit olmalı",
    numericalityLessThanMessage: "{count}'dan küçük olmalı",
    numericalityLessThanOrEqualToMessage: "{count}'a eşit veya daha küçük olmalı",
    numericalityOddMessage: 'tek sayı olmalı',
    numericalityEvenMessage: 'çift sayı olmalı',
    mailMessage: 'geçerli bir e-posta değil',
    formatMessage: 'geçersiz',
    colorMessage: 'geçerli bir CSS hex renk kodu olmalı',
    subdomainMessage: 'geçerli bir alt alan adı olmalı',
    accepttanceMessage: 'kabul edilmeli',
    zipCodeMessage: 'geçerli bir posta kodu değil',
    URLMessage: 'geçerli bir URL olmalı',
    wrongLengthMessage: 'yanlış uzunlukta ({count} karakter olmalı)',
    tooShortMessage: 'çok kısa (minimum {count} karakter)',
    tooLongMessage: 'çok uzun (maksimum {count} karakter)',
    mustContainNumberMessage: 'bir sayı içermeli',
    mustContainSpecialMessage: 'bu özel karakterlerden birini içermeli: {characters}',
    mustContainLowerMessage: 'küçük harf içermeli',
    mustContainCapitalMessage: 'büyük harf içermeli',
    customValidationMessage: 'geçersiz',
    matchMessage: '{match} ile eşleşmeli',
    dateMessage: 'geçerli bir tarih değil',
    dateBeforeMessage: "{date}'dan öncesi olmalı",
    dateAfterMessage: "{date}'dan sonrası olmalı"
  };
  _exports.default = _default;
});