define("ember-svg-jar/inlined/thumbsdown-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.683 3.077A1.31 1.31 0 0018.37 2H16.683v9H18.37a1.31 1.31 0 001.313-1.076V3.077zm-5 8.711V2H4.39a1 1 0 00-1 .85l-1.38 9a1.001 1.001 0 001 1.15h5.672a1 1 0 011 1v4c0 .893.584 1.649 1.392 1.906l3.608-8.118zM16.333 13l-3.736 8.407a1 1 0 01-.914.593 4 4 0 01-4-4v-3H3.028a3 3 0 01-2.994-3.45l1.38-9A3 3 0 014.408 0h13.937a3.31 3.31 0 013.329 2.866c.006.045.009.09.009.134v7c0 .045-.003.09-.01.134A3.31 3.31 0 0118.346 13h-2.012z\" fill=\"#000\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});