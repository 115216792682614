define("ember-svg-jar/inlined/dropdown-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.841 1.659L5.297 5.705a1 1 0 01-1.504 0L.248 1.659A1 1 0 011 0h7.09a1 1 0 01.751 1.659z\" fill=\"#000\"/>",
    "attrs": {
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});