define("accounting/index", ["exports", "accounting/format", "accounting/format-column", "accounting/format-money", "accounting/format-number", "accounting/parse", "accounting/settings", "accounting/to-fixed", "accounting/unformat", "accounting/version"], function (_exports, _format, _formatColumn, _formatMoney, _formatNumber, _parse, _settings, _toFixed, _unformat, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "format", {
    enumerable: true,
    get: function () {
      return _format.default;
    }
  });
  Object.defineProperty(_exports, "formatColumn", {
    enumerable: true,
    get: function () {
      return _formatColumn.default;
    }
  });
  Object.defineProperty(_exports, "formatMoney", {
    enumerable: true,
    get: function () {
      return _formatMoney.default;
    }
  });
  Object.defineProperty(_exports, "formatNumber", {
    enumerable: true,
    get: function () {
      return _formatNumber.default;
    }
  });
  Object.defineProperty(_exports, "parse", {
    enumerable: true,
    get: function () {
      return _parse.default;
    }
  });
  Object.defineProperty(_exports, "settings", {
    enumerable: true,
    get: function () {
      return _settings.default;
    }
  });
  Object.defineProperty(_exports, "toFixed", {
    enumerable: true,
    get: function () {
      return _toFixed.default;
    }
  });
  Object.defineProperty(_exports, "unformat", {
    enumerable: true,
    get: function () {
      return _unformat.default;
    }
  });
  Object.defineProperty(_exports, "version", {
    enumerable: true,
    get: function () {
      return _version.default;
    }
  });
  var _default = {
    format: _format.default,
    formatColumn: _formatColumn.default,
    formatMoney: _formatMoney.default,
    formatNumber: _formatNumber.default,
    parse: _parse.default,
    settings: _settings.default,
    toFixed: _toFixed.default,
    unformat: _unformat.default,
    version: _version.default
  };
  _exports.default = _default;
});