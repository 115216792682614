define("ember-svg-jar/inlined/edit-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.04.54a.65.65 0 01.92 0l2.5 2.5a.65.65 0 010 .92l-6.5 6.5a.65.65 0 01-.46.19H1A.65.65 0 01.35 10V7.5a.65.65 0 01.19-.46l6.5-6.5zM1.65 7.77v1.58h1.58L9.08 3.5 7.5 1.92 1.65 7.77z\" fill=\"#000\"/>",
    "attrs": {
      "width": "11",
      "height": "11",
      "viewBox": "0 0 11 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});