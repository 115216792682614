define("ember-cli-json-pretty/components/json-pretty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "glaIgW5X",
    "block": "[[[10,\"pre\"],[12],[1,[34,0]],[13]],[],false,[\"preformattedText\"]]",
    "moduleName": "ember-cli-json-pretty/components/json-pretty/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});