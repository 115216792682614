define("ember-svg-jar/inlined/heart-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.995 1.945A6.95 6.95 0 0019.157 0a6.95 6.95 0 00-4.839 1.945L13 3.224 11.68 1.945c-2.672-2.592-7.004-2.592-9.677 0a6.499 6.499 0 000 9.388l1.319 1.279L13 22l9.677-9.388 1.318-1.28A6.538 6.538 0 0026 6.64c0-1.76-.72-3.45-2.005-4.694zm-7.99 4.936a1 1 0 010 1.414l-1.768 1.768 1.768 1.768a1 1 0 01-1.414 1.414l-1.768-1.768-1.768 1.768a1 1 0 01-1.414-1.414l1.768-1.768-1.768-1.768a1 1 0 111.415-1.414l1.767 1.768 1.768-1.768a1 1 0 011.414 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "26",
      "height": "22",
      "viewBox": "-2 0 30 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});