/*
 Copyright 2013 Logentries.
 Please view license at https://raw.github.com/logentries/le_js/master/LICENSE
*/
'use strict';(function(c,e){"function"===typeof define&&define.amd?define([c],e):"object"===typeof exports?module.exports=e(c):c.LE=e(c)})(this,function(c){function e(a){var b=(Math.random()+Math.PI).toString(36).substring(2,10),s=a.trace,e=a.page_info,f=a.token,g=a.print,p;p="undefined"===typeof XDomainRequest?a.ssl:"https:"===c.location.protocol?!0:!1;var h=[],k=!1,q=!1;if(a.catchall){var r=c.onerror;c.onerror=function(a,b,d){l({error:a,line:d,location:b}).level("ERROR").send();return r?r(a,b,d):
!1}}var n=function(){var a=c.navigator||{doNotTrack:void 0},b=c.screen||{};return{url:(c.location||{}).pathname,referrer:document.referrer,screen:{width:b.width,height:b.height},window:{width:c.innerWidth,height:c.innerHeight},browser:{name:a.appName,version:a.appVersion,cookie_enabled:a.cookieEnabled,do_not_track:a.doNotTrack},platform:a.platform}},t=function(){var a=null,a=Array.prototype.slice.call(arguments);if(0===a.length)throw Error("No arguments!");return a=1===a.length?a[0]:a},l=function(a){var c=
t.apply(this,arguments),d={event:c};"never"===e||q&&"per-entry"!==e||(q=!0,"undefined"===typeof c.screen&&"undefined"===typeof c.browser&&l(n()).level("PAGE").send());s&&(d.trace=b);return{level:function(a){if(g&&"undefined"!==typeof console&&"PAGE"!==a)try{console[a.toLowerCase()].call(console,d)}catch(b){console.log(d)}d.level=a;return{send:function(){var a=[],b=JSON.stringify(d,function(b,d){if("undefined"===typeof d)return"undefined";if("object"===typeof d&&null!==d){var c;a:{for(c=0;c<a.length;c++)if(d===
a[c])break a;c=-1}if(-1!==c)return"<?>";a.push(d)}return d});k?h.push(b):m(f,b)}}}}};this.log=l;var m=function(a,b){k=!0;var d;d="undefined"!==typeof XDomainRequest?new XDomainRequest:new XMLHttpRequest;d.constructor===XMLHttpRequest?d.onreadystatechange=function(){4===d.readyState&&(400<=d.status?(console.error("Couldn't submit events."),410===d.status&&console.warn("This version of le_js is no longer supported!")):(301===d.status&&console.warn("This version of le_js is deprecated! Consider upgrading."),
0<h.length?m(a,h.shift()):k=!1))}:d.onload=function(){0<h.length?m(a,h.shift()):k=!1};d.open("POST",(p?"https://":"http://")+"js.logentries.com/v1/logs/"+f,!0);d.constructor===XMLHttpRequest&&(d.setRequestHeader("X-Requested-With","XMLHttpRequest"),d.setRequestHeader("Content-type","text/json"));d.send(b)}}function n(a){var b,c={ssl:!0,catchall:!1,trace:!0,page_info:"never",print:!1,token:null};if("object"===typeof a)for(var g in a)c[g]=a[g];else throw Error("Invalid parameters for createLogStream()");
if(null===c.token)throw Error("Token not present.");b=new e(c);var f=function(a){if(b)return b.log.apply(this,arguments);throw Error("You must call LE.init(...) first.");};return{log:function(){f.apply(this,arguments).level("LOG").send()},warn:function(){f.apply(this,arguments).level("WARN").send()},error:function(){f.apply(this,arguments).level("ERROR").send()},info:function(){f.apply(this,arguments).level("INFO").send()}}}var b={},g=function(a){if("string"!==typeof a.name)throw Error("Name not present.");
if(b.hasOwnProperty(a.name))throw Error("Alrready exist this name for a logStream");b[a.name]=new n(a);return!0};return{init:function(a){var b={name:"default"};if("object"===typeof a)for(var c in a)b[c]=a[c];else if("string"===typeof a)b.token=a;else throw Error("Invalid parameters for init()");return g(b)},createLogStream:g,to:function(a){if(!b.hasOwnProperty(a))throw Error("Invalid name for logStream");return b[a]},destroy:function(a){"undefined"===typeof a&&(a="default");delete b[a]},log:function(){for(var a in b)b[a].log.apply(this,
arguments)},warn:function(){for(var a in b)b[a].warn.apply(this,arguments)},error:function(){for(var a in b)b[a].error.apply(this,arguments)},info:function(){for(var a in b)b[a].info.apply(this,arguments)}}});
