define("ember-svg-jar/inlined/diner-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.667 4.667h-15l.772-2.317a1 1 0 01.95-.683h11.557a1 1 0 01.949.683l.772 2.317zM6.667 4.667a2.5 2.5 0 11-5 0M11.667 4.667a2.5 2.5 0 11-5 0M16.667 4.667a2.5 2.5 0 11-5 0M6.667 1.667v3M11.667 1.667v3M2.667 6.667v10M15.667 6.667v10M1.667 11.666h15M5.167 13.666h3M5.667 16.666l1-3 1 3M10.667 13.666h3M11.167 16.666l1-3 1 3\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});