define("ember-svg-jar/inlined/meal-plan-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.206 2.088a1.5 1.5 0 00-3 0v2.853H2a1.5 1.5 0 00-1.5 1.5v30.471a1.5 1.5 0 001.5 1.5h18.5a1.5 1.5 0 000-3h-17V17.389h34v4.288a1.5 1.5 0 003 0V6.44a1.5 1.5 0 00-1.5-1.5h-7.206V2.088a1.5 1.5 0 10-3 0v2.853H12.206V2.088zM37.5 14.39V7.941h-5.706v2.853a1.5 1.5 0 11-3 0V7.941H12.206v2.853a1.5 1.5 0 11-3 0V7.941H3.5v6.448h34z\" fill=\"#D84B44\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.244 25.509a1.5 1.5 0 00-3 0v11a3.5 3.5 0 002.01 3.167v13.332a1.5 1.5 0 103 0v-13.34a3.5 3.5 0 001.99-3.16v-11a1.5 1.5 0 00-3 0v11a.5.5 0 01-.262.44 1.515 1.515 0 00-.471.003.5.5 0 01-.267-.443v-11zm32.097.521c-.572-1.249-1.851-1.576-2.83-1.307-.952.262-1.852 1.137-1.852 2.42v25.365a1.5 1.5 0 003 0V46.48h3.085a1.5 1.5 0 001.5-1.5c0-10.395-1.452-15.782-2.903-18.95zm-.107 17.45h-1.575V30.154c.798 2.778 1.482 6.932 1.575 13.326zM33.812 29.312a1.5 1.5 0 002.12 2.12c4.315-4.313 11.31-4.313 15.624 0a1.5 1.5 0 002.121-2.12c-5.486-5.486-14.38-5.486-19.865 0zm2.12 17.744a1.5 1.5 0 10-2.12 2.121c5.485 5.486 14.38 5.486 19.865 0a1.5 1.5 0 10-2.121-2.121c-4.314 4.314-11.309 4.314-15.623 0z\" fill=\"#636A77\"/>",
    "attrs": {
      "width": "64",
      "height": "55",
      "viewBox": "0 0 64 55",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});