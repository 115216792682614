define("ember-svg-jar/inlined/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.676.263a1 1 0 01.061 1.413l-5.5 6a1 1 0 01-1.474 0l-2.5-2.728a1 1 0 011.474-1.351L3.5 5.52 8.263.324A1 1 0 019.676.263z\" fill=\"#000\"/>",
    "attrs": {
      "width": "10",
      "height": "8",
      "viewBox": "0 0 10 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});