define("ember-svg-jar/inlined/heart-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.953 2.09a6.277 6.277 0 014.52-1.94c1.701 0 3.326.702 4.52 1.94a6.692 6.692 0 011.857 4.644 6.692 6.692 0 01-1.857 4.643l-8.881 9.213a.85.85 0 01-1.224 0l-8.881-9.213C-.47 8.81-.47 4.658 2.007 2.09a6.237 6.237 0 019.04 0l.453.47.453-.47zm4.52-.24c-1.23 0-2.417.507-3.296 1.42l-1.065 1.104a.85.85 0 01-1.224 0L9.823 3.27a4.537 4.537 0 00-6.592 0c-1.841 1.91-1.841 5.018 0 6.928l8.269 8.577 8.269-8.577a4.992 4.992 0 001.381-3.464c0-1.305-.5-2.55-1.381-3.464a4.578 4.578 0 00-3.296-1.42zM11.5 6a1 1 0 011 1v1.5H14a1 1 0 110 2h-1.5V12a1 1 0 11-2 0v-1.5H9a1 1 0 110-2h1.5V7a1 1 0 011-1z\" fill=\"#000\"/>",
    "attrs": {
      "width": "23",
      "height": "21",
      "viewBox": "0 0 23 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});