define("ember-cli-clock/services/clock", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "ember"], function (_exports, _service, _object, _runloop, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    interval: 1000,
    intervalTime: 1000,
    second: 0,
    minute: 0,
    five: 0,
    quarter: 0,
    hour: 0,
    time: null,
    date: (0, _object.computed)('time', function () {
      return new Date(this.get('time'));
    }),
    init() {
      this._super(...arguments);
      this.start();
    },
    start() {
      this.update();
      this.set('intervalId', window.setInterval(() => this.update(), this.get('interval')));
    },
    stop() {
      window.clearInterval(this.get('intervalId'));
    },
    willDestroy() {
      this._super(...arguments);
      this.stop();
    },
    onIntervalChange: (0, _object.observer)('interval', function () {
      this.stop();
      this.start();
    }),
    update() {
      (0, _runloop.run)(() => this.set('time', Date.now()));
    },
    reset() {
      this.stop();
      this.setProperties({
        second: 0,
        minute: 0,
        five: 0,
        quarter: 0,
        hour: 0
      });
      this.start();
    },
    intervalChange: (0, _object.observer)('intervalTime', function () {
      if (_ember.default.testing) {
        this.set('interval', this.get('intervalTime'));
        return this.reset();
      }
      throw Error('The clock interval cannot be changed except during testing');
    }),
    timeChange: (0, _object.observer)('time', function () {
      this.tick();
    }),
    tick() {
      let second = this.incrementProperty('second');
      if (second && second % 60 === 0) {
        let minute = this.incrementProperty('minute');
        if (minute !== 0) {
          if (minute % 5 === 0) {
            this.incrementProperty('five');
          }
          if (minute % 15 === 0) {
            this.incrementProperty('quarter');
          }
          if (minute % 60 === 0) {
            this.incrementProperty('hour');
          }
        }
      }
    }
  });
  _exports.default = _default;
});