define("semantic-ui-ember/components/ui-search", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-search"], function (_exports, _ember, _base, _uiSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _ember.default.Component.extend(_base.default, {
    layout: _uiSearch.default,
    module: 'search',
    classNames: ['ui', 'search'],
    didInitSemantic() {
      this._super(...arguments);
      this.get('_bindableAttrs').addObject('source');
    },
    execute() {
      const cmd = arguments.length <= 0 ? undefined : arguments[0];
      const attrValue = arguments.length <= 1 ? undefined : arguments[1];
      if (cmd === 'set source') {
        let module = this.getSemanticModule();
        if (module) {
          return module.apply(this.getSemanticScope(), [{
            source: attrValue
          }]);
        }
      }
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});