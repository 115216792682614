define("ember-custom-actions/serializers/rest", ["exports", "@ember/debug", "ember-data"], function (_exports, _debug, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer
  } = _emberData.default;
  var _default = RESTSerializer.extend({
    init() {
      this._super(...arguments);
      (false && !(false) && (0, _debug.deprecate)('Using ember-custom-actions `RestSerializer` is no longer required and this class will be removed.', false, {
        id: 'ember-custom-actions.deprecate-jsonapi-serializer',
        until: '3.0.0'
      }));
    }
  });
  _exports.default = _default;
});