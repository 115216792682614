define("ember-semantic-ui-calendar/templates/components/ui-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5jRhvTFM",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],[[30,0],\"execute\"],null]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ui input \",[36,4]]]],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[10,\"i\"],[15,0,[29,[\"left \",[36,5],\" icon\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"input\"],[15,\"placeholder\",[36,6]],[15,\"onkeydown\",[28,[37,3],[[30,0],\"handleInputKeyDown\"],null]],[15,\"onfocus\",[28,[37,3],[[30,0],\"handleInputFocus\"],null]],[15,\"onblur\",[28,[37,3],[[30,0],\"handleInputBlur\"],null]],[14,4,\"text\"],[12],[13],[1,\"\\n\"],[41,[33,7],[[[1,\"      \"],[11,\"i\"],[16,0,[29,[\"right \",[36,8],\" link icon\"]]],[4,[38,3],[[30,0],\"execute\",\"clear\"],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"action\",\"inputIconsClassNames\",\"icon\",\"placeholder\",\"showClearButton\",\"clearIcon\"]]",
    "moduleName": "ember-semantic-ui-calendar/templates/components/ui-calendar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});