define("ember-svg-jar/inlined/restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M4.5.75H21a1.5 1.5 0 011.5 1.5v19.5a1.5 1.5 0 01-1.5 1.5H4.5a1.5 1.5 0 01-1.5-1.5V2.25A1.5 1.5 0 014.5.75z\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1.5 5.25h3M1.5 9.75h3M1.5 14.25h3M1.5 18.75h3\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M10.5 6c1.243 0 2.25 1.175 2.25 2.625s-1.007 2.625-2.25 2.625-2.25-1.175-2.25-2.625S9.257 6 10.5 6z\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10.5 11.25v6M16.5 11.25v6M15 6v3.75a1.5 1.5 0 001.5 1.5v0a1.5 1.5 0 001.5-1.5V6\" stroke=\"#C9C9C9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});