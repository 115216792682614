define("ember-svg-jar/inlined/right-arrow-chevron-thin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.78 6.72A.75.75 0 007 6.19v-.38a.77.77 0 00-.22-.53L1.64.15a.5.5 0 00-.71 0L.22.86a.49.49 0 000 .7L4.67 6 .22 10.44a.5.5 0 000 .71l.71.7a.5.5 0 00.71 0l5.14-5.13z\" fill=\"#52575C\"/>",
    "attrs": {
      "width": "7",
      "height": "12",
      "viewBox": "0 0 7 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});