define("ember-ace/index", ["exports", "ember-ace/worker-manifest"], function (_exports, _workerManifest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Tokenizer = _exports.TextMode = _exports.TextHighlightRules = _exports.Range = void 0;
  /* global ace */
  var _default = ace;
  _exports.default = _default;
  const Tokenizer = ace.require('ace/tokenizer').Tokenizer;
  _exports.Tokenizer = Tokenizer;
  const Range = ace.require('ace/range').Range;
  _exports.Range = Range;
  const TextMode = ace.require('ace/mode/text').Mode;
  _exports.TextMode = TextMode;
  const TextHighlightRules = ace.require('ace/mode/text_highlight_rules').TextHighlightRules;
  _exports.TextHighlightRules = TextHighlightRules;
  const config = ace.require('ace/config');
  Object.keys(_workerManifest.default).forEach(key => {
    config.setModuleUrl(key, _workerManifest.default[key]);
  });
});