define("ember-svg-jar/inlined/heart-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.473.15a6.277 6.277 0 00-4.52 1.94l-.453.47-.453-.47a6.237 6.237 0 00-9.04 0c-2.476 2.568-2.476 6.72 0 9.287l8.88 9.213a.85.85 0 001.225 0l8.88-9.213a6.692 6.692 0 001.858-4.643 6.692 6.692 0 00-1.858-4.644 6.277 6.277 0 00-4.52-1.94z\" fill=\"#000\"/>",
    "attrs": {
      "width": "23",
      "height": "21",
      "viewBox": "-2 0 27 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});