define("liquid-fire/components/liquid-child", ["exports", "rsvp", "@ember/component"], function (_exports, _rsvp, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['liquid-child'],
    init() {
      this._super(...arguments);
      this._waitingFor = [];
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.element) {
        this.element.style.visibility = 'hidden';
      }
      this._waitForAll().then(() => {
        if (!this.isDestroying) {
          this._waitingFor = null;
          const didRenderAction = this.liquidChildDidRender;
          if (typeof didRenderAction === 'function') {
            didRenderAction(this);
          }
        }
      });
    },
    _isLiquidChild: true,
    _waitForMe(promise) {
      if (!this._waitingFor) {
        return;
      }
      this._waitingFor.push(promise);
      let ancestor = this.nearestWithProperty('_isLiquidChild');
      if (ancestor) {
        ancestor._waitForMe(promise);
      }
    },
    _waitForAll() {
      const promises = this._waitingFor;
      this._waitingFor = [];
      return _rsvp.Promise.all(promises).then(() => {
        if (this._waitingFor.length > 0) {
          return this._waitForAll();
        }
      });
    }
  });
  _exports.default = _default;
});