define("ember-svg-jar/inlined/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" d=\"M63 48a6 6 0 01-6 6H21L9 66V12a6 6 0 016-6h42a6 6 0 016 6v36z\" stroke=\"#26282C\" stroke-width=\"6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M37.145 17.21v12M37.145 41.21h.03\" stroke=\"#26282C\" stroke-width=\"6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "72",
      "height": "72",
      "viewBox": "0 0 72 72",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});